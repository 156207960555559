import { useEffect, useState } from "react";
import { isMobile as isMobileDetect } from "react-device-detect";

import styles from "./Panel.module.scss";
import ProgressBar from "/components/experiments/reskin/molecules/ProgressBar";
import { PROGRESSBAR_ROUTING } from "/config/PageRouting";
import { useExperiment } from "../../../../contexts/ExperimentContext";
import { css, StyleSheet } from "aphrodite";

export default function Panel({
  children,
  className,
  large,
  showProgressBar,
  progressType,
  currentStep,
  step,
}) {
  const { experiment } = useExperiment();

  const [isMobile, setIsMobile] = useState(isMobileDetect);

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  const classNames = `${styles["Panel"]} ${className ? className : ""} ${
    large ? styles["--large"] : ""
  } ${showProgressBar && currentStep ? styles["--progress-bar"] : ""} ${
    currentStep ? styles["--large-new"] : ""
  }`.trim();
  const steps = PROGRESSBAR_ROUTING(experiment)[progressType] || [];

  return (
    <div className={classNames}>
      <div className={css(!currentStep && aphStyles.desktop)}>
        {showProgressBar && (
          <ProgressBar maxSteps={steps.length} currentStep={currentStep} />
        )}
      </div>
      <div
        className={[
          styles["Panel__container"],
          (!isMobile || currentStep) && step !== "mrc"
            ? styles["--container-new"]
            : "",
          !isMobile &&
            !currentStep &&
            step !== "mrc" &&
            styles["--container-new-landing"],
          step === "rocket" && styles["--container-rocket"],
        ].join(" ")}
      >
        {children}
      </div>
    </div>
  );
}

const aphStyles = StyleSheet.create({
  desktop: {
    "@media only screen and (max-width: 1023px)": {
      display: "none",
    },
  },
});
