import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ProgressBar.module.scss";
import { StyleSheet, css } from "aphrodite";
import { isMobile as isMobileDetect } from "react-device-detect";

import { useExperiment } from "../../../../contexts/ExperimentContext";
import { isNextSubmit } from "../../../../config/PageRouting";
import { useCallback } from "react";

/**
 * ProgressBar
 *
 * Progress bar component that we can animate.
 *
 * @param {*} props
 * @returns
 */
const ProgressBar = (props) => {
  const { maxSteps, currentStep } = props;
  const { experiment } = useExperiment();

  const [statePercentage, setStatePercentage] = useState(8);

  const [isMobile, setIsMobile] = useState(isMobileDetect);

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  const isSubmit = isNextSubmit(experiment);

  const floor = useCallback(() => {
    if (isSubmit) {
      return 1;
    }

    if (currentStep / maxSteps < 0.19) {
      return 0.19;
    } else {
      return currentStep / maxSteps;
    }
  }, [currentStep, isSubmit, maxSteps]);

  useEffect(() => {
    setStatePercentage(floor() * 100);
  }, [currentStep, floor]);

  const className = `${styles["ProgressBar__inner"]} ${
    currentStep >= maxSteps ? styles["--full"] : ""
  }`.trim();

  const leftPercentage = `${floor() * 100}%`;

  const dynamicStyle = {
    left: leftPercentage,
  };

  const shouldShowCallouts =
    currentStep !== 0 && (!isMobile ? currentStep : currentStep === maxSteps);

  return (
    <div
      className={[
        styles["ProgressBar"],
        !isMobile && styles["ProgressBarNew"],
      ].join(" ")}
    >
      <div
        className={className}
        style={{ maxWidth: `${statePercentage}%` }}
      ></div>
      {shouldShowCallouts ? (
        <div
          style={{ left: `${statePercentage}%` }}
          className={
            styles.callout +
            " " +
            css(
              aphStyles.callout,
              (currentStep === maxSteps || isSubmit) && aphStyles.calloutLast
            )
          }
        >
          {currentStep === maxSteps || isSubmit
            ? "Last Step!"
            : `Step ${currentStep} / ${maxSteps}`}
        </div>
      ) : null}
    </div>
  );
};

const aphStyles = StyleSheet.create({
  callout: {
    textAlign: "center",
    minWidth: 115,
  },
  calloutLast: {
    width: 120,
    left: "93%",

    "@media only screen and (max-width: 1023px)": {
      right: 16,
      left: "unset",
      transform: "unset",
    },
  },
});

ProgressBar.propTypes = {
  maxSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  maxSteps: 100,
  currentStep: 0,
};

export default ProgressBar;
